<template>
  <div class="page">
    <div class="page-header">
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeParamsVersion"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 150px"
        v-model="params.versionId"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="items of notConfirmVersionList"
          :key="items.versionId"
          :value="items.versionId"
          >{{ items.versionName }}</a-select-option
        >
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        size="small"
        style="margin: 5px; min-width: 120px"
        v-model="params.scoreId"
        @change="getList()"
        placeholder="评分"
      >
        <a-select-option
          v-for="item of searchRanks"
          :key="item.id"
          :value="item.id"
          >{{ item.scoreName }}</a-select-option
        >
      </a-select>

      <a-select
        :dropdownMatchSelectWidth="false"
        @change="changeSource"
        allowClear
        size="small"
        style="width: 150px; margin: 5px"
        v-model="params.goodsSource"
        placeholder="数据源"
      >
        <a-select-option :value="1">HX</a-select-option>
        <a-select-option :value="3">WPT</a-select-option>
        <a-select-option :value="4">XZ</a-select-option>
        <a-select-option :value="10">SBP</a-select-option>
        <a-select-option :value="2">QBTT</a-select-option>
      </a-select>

      <a-range-picker
        v-model="times"
        :placeholder="['开始时间', '结束时间']"
        size="small"
        style="margin: 5px"
        @change="timeChange"
      />
      <a-button @click="search" size="small" style="margin-left: 10px"
        >搜索</a-button
      >
      <!-- 白币确认 -->
      <a-popconfirm
        title="Are you sure confirm this item?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="batchSet(1)"
        :disabled="isDisabled"
      >
        <a-button :disabled="isDisabled" size="small" style="margin-left: 10px"
          >白币确认</a-button
        >
      </a-popconfirm>
      <!-- 非白币确认 -->
      <a-popconfirm
        title="Are you sure confirm this item?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="batchSet(2)"
        :disabled="isDisabled"
      >
        <a-button :disabled="isDisabled" size="small" style="margin-left: 10px"
          >非白币确认</a-button
        >
      </a-popconfirm>
    </div>
    <div class="page-body">
      <PageTable
        :type="2"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @selectChange="onSelectChange"
        @changePage="changePage"
        @priceSortChange="priceSortChange"
        @timeSortChange="timeSortChange"
        @success="getList"
        @delete="deleteItem"
        @edit="editPage"
      ></PageTable>
    </div>
    <EditDollar ref="edit" :detail="detail" v-if="detail" @close="detail = ''" @success="getList"></EditDollar>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/dollarManager/_components/PageTable.vue";
import EditDollar from "@/views/cmsPage/dollarManager/_components/EditDollar.vue";
export default {
  data() {
    return {
      list: [],
      params: {
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        type: 0,
        goodsType: 1,
        reviewStatus: 0,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      searchRanks: [],
      notConfirmVersionList: [],
      selctedArrays: [],
      times: [],
      loading: false,
      detail: ""
    };
  },
  computed:{
    isDisabled() {
      if (this.selctedArrays.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    PageTable,
    EditDollar
  },
  created() {
    if (this.$route.query.code) {
      this.params.code = this.$route.query.code
    }
    this.getConfirmVersions();
    this.getList()
    
  },
  mounted(){
    this.$eventBus.$on("searchCode",(code)=>{
      this.params = JSON.parse(JSON.stringify({
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        type: 2,
        goodsType: 1,
        reviewStatus: 0,
        code
      }))
      this.getList()
    })
  },
  destroyed(){
    this.$eventBus.$off("searchCode")
  },
  methods: {
    deleteItem(id){
      const index = this.list.findIndex(el=>{
        return el.id == id
      })
      if (index > -1) {
        this.list.splice(index, 1)
      }
    },
    // 打开编辑页
    editPage(row){
      this.detail = JSON.parse(JSON.stringify(row))
    },
    search(){
      this.params.pageNum = 1
      this.pagination.current = 1
      this.getList()
    },
    priceSortChange(val){
      this.params.sort = val
      delete this.params.timeSort
      this.search()
    },
    timeSortChange(val){
      this.params.timeSort = val
      delete this.params.sort
      this.search()
    },
    // 切换分页
    changePage(page){
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    // 白币非白币确认按钮
    async batchSet(type) {
      if (!this.selctedArrays || this.selctedArrays.length < 1) {
        return this.$message.warning("请选择");
      }
      let ids = this.selctedArrays.map((row) => row.id);
      ids = ids.join(",");
      this.batchConfirmation(ids, type);
    },
    // 确认白币非白币
    async batchConfirmation(ids, type) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/batchConfirmation",
        {
          params: {
            silverGoodsIds: ids,
            type: type,
          },
        }
      );
      if (res.status != 200) return;
      this.$message.success(res.message || "请求成功");
      this.getList();
    },
    // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays)) ; 
    },
    // 筛选时间
    timeChange(array) {
      if (!array || array.length <= 0) {
        delete this.params.startTime;
        delete this.params.endTime;
      } else {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.startTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.endTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      }

      this.getConfirmVersions();
      delete this.params.versionId;
      delete this.params.scoreId;
    },
    // 数据源
    changeSource() {
      delete this.params.versionId;
      delete this.params.scoreId;
      this.getConfirmVersions();
    },
    // 版别筛选
    changeParamsVersion(e) {
      delete this.params.scoreId;
      this.getRateListForVersionId();
      this.getList();
    },
    // 搜索分数
    async getRateListForVersionId() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenScoreList",
        {
          params: this.params,
        }
      );
      if (res.status != 200) return;
      const { data } = res;
      this.searchRanks = data;
    },
    //
    async getList() {
      this.loading = true
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/list",
        {
          params: this.params,
        }
      );
      this.loading = false
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
      delete this.params.code
    },
    // 版别列表
    async getConfirmVersions() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenList",
        {
          params: {
            startTime: this.params.startTime,
            endTime: this.params.endTime,
            goodsType: this.params.goodsType,
            goodsSource: this.params.goodsSource,
          },
        }
      );
      if (res.status != 200) return;
      this.notConfirmVersionList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
</style>